import { Link } from "gatsby"
// import Img from "gatsby-image"
import React, { useState } from "react"
import logo_svg from "../images/logo-full-white.svg"
import {
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
} from "reactstrap"

const Header = ({ siteTitle, className }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <header className={className}>
      <Navbar expand="lg" dark fixed="top">
        <NavbarBrand>
          <Link to="/" className="navbar-brand">
            <img
              src={logo_svg}
              className="logo"
              alt={siteTitle}
              title={siteTitle}
            />
          </Link>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/">O&nbsp;nas</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/realizacje">Realizacje</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/fabryka">Fabryka</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/galeria">Galeria</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/tvn">Tvn</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/kontakt">Kontakt</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  )
}

export default Header
