import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import ReactModal from "react-modal"

import Header from "./header"
import "../css/layout.scss"
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"
import logo_svg from "../images/logo-full-white.svg"

import img_footer_marker from "../images/footer-marker.png"
import img_footer_phone from "../images/footer-phone.png"
import img_footer_mail from "../images/footer-mail.png"
import img_footer_facebook from "../images/footer-facebook.png"
import img_footer_instagram from "../images/footer-instagram.png"


const Layout = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} className={className} />
      <main className={className}>{children}</main>
      <footer>
        <Container>
          <Row>
            <Col md="6" className="text-left">
              <img
                src={logo_svg}
                className="logo"
                alt="Nobo Design"
                title="Nobo Design"
              />
            </Col>
            <Col md="6" className="mt-5">
              <Row>
                <Col md="6">
                  <Row className="footer-card">
                    <Col md="3">
                      <a href="https://g.page/nobodesign-eu?share" target="_blank" rel="noreferrer">
                        <img src={img_footer_marker} alt="Adres" title="Adres" />
                      </a>
                    </Col>
                    <Col md="9">
                      <a href="https://g.page/nobodesign-eu?share" target="_blank" rel="noreferrer">
                        Nobo Design <br/>
                        ul. Ząbkowska 14 lok. U3<br />
                        03-735 Warszawa
                      </a>
                    </Col>
                  </Row>
                  <Row className="footer-card">
                    <Col md="3">
                      <a href="tel:+48 888 999 604">
                        <img src={img_footer_phone} alt="Telefon" title="Telefon" />
                      </a>
                    </Col>
                    <Col md="9">
                      <a href="tel:+48 888 999 604">+48 888 999 604</a>
                    </Col>
                  </Row>
                  <Row className="footer-card">
                    <Col md="3">
                      <a 
                        href="#"
                        data-name="biuro" 
                        data-domain="nobodesign" 
                        data-tld="eu"
                        onClick={e => {
                          e.preventDefault();
                          window.location.href = 'mailto:' + e.target.dataset.name + '@' + e.target.dataset.domain + '.' + e.target.dataset.tld;
                        }}
                      >
                      <img src={img_footer_mail} alt="E-mail" title="E-mail" />
                      </a>
                    </Col>
                    <Col md="9">
                      <a 
                        href="#" 
                        className="cryptedmail" 
                        data-name="biuro" 
                        data-domain="nobodesign" 
                        data-tld="eu"
                        onClick={e => {
                          e.preventDefault();
                          window.location.href = 'mailto:' + e.target.dataset.name + '@' + e.target.dataset.domain + '.' + e.target.dataset.tld;
                        }}
                      >&nbsp;</a>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <Row className="footer-card">
                    <Col md="3">
                      <a href="https://facebook.com/nobodesign.eu" target="_blank" rel="noreferrer">
                        <img src={img_footer_facebook} alt="Facebook" title="Facebook" />
                      </a>
                    </Col>
                    <Col md="9">
                      <a href="https://facebook.com/nobodesign.eu" target="_blank" rel="noreferrer">facebook.com/nobodesign.eu</a>
                    </Col>
                  </Row>
                  <Row className="footer-card">
                    <Col md="3">
                      <a href="https://instagram.com/nobo_design" target="_blank" rel="noreferrer">
                        <img src={img_footer_instagram} alt="Instagram" title="Instagram" />
                      </a>
                    </Col>
                    <Col md="9">
                      <a href="https://instagram.com/nobo_design" target="_blank" rel="noreferrer">instagram.com/nobo_design</a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Row className="copyrights">
          <Col>
            <Nav className="ml-auto mx-auto" justified="true">
              <NavItem>
                <NavLink href="/">O&nbsp;nas</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/realizacje">Realizacje</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/fabryka">Fabryka</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/galeria">Galeria</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/kontakt">Kontakt</NavLink>
              </NavItem>
            </Nav>
            © 2020 Studio Projektowe Nobo Design Wszystkie prawa zastrzeżone
          </Col>
        </Row>
      </footer>
    </>
  )
}
ReactModal.setAppElement("#___gatsby")
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
